import { useEffect, useState } from "react";
import Button from "../components/button";
import Input from "../components/input";
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Info from '../components/info';
import { saveItem,handleError } from "../utils";
import { AdminSignIn } from "../utils/endpoints";



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', width: '100%', height: '(100dvh - 100px)',
    },
    body: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', width: '500px', border: 'thin solid lightgray', borderRadius: 10, marginTop: '7%',
        padding: 40, minHeight: 400, marginLeft: 'auto', marginRight: 'auto'
    }
}));

export const AdminLogin = ({ NextPage }) => {
    const [formData, setFormData] = useState({ password: '', adminId: '' });
    const [btnLoading, setBtnLoading] = useState(false);
    const [PwdError, setPwdError] = useState('');
    const [IDError, setIDError] = useState('');
    const [message, setMessage] = useState({status:'',msg:''});
    const classes = useStyles();

    const nav = useNavigate();

    useEffect(() => {
        if (document.querySelector('.no-desktop')) {
            document.body.removeChild(document.querySelector('.no-desktop'));
        }
    }, [])

    const ProcessLogin = async () => {
        setBtnLoading(true);
        AdminSignIn(formData).then(({ data: { data } }) => {
            saveItem('__FLASHUSDT_ADMIN__',data, 'SS');
            NextPage && NextPage(1);

        }).catch((err) => {
            setMessage({status:true,msg:handleError(err)});
            setBtnLoading(false);
        })

    }

    return (
        <div className={classes.root}>
            <div className={classes.body}>
                <h2>Admin Login</h2>

                <Info display={message.status} autoRemove = {true} setDisplay={(st)=>setMessage({...message,status:st})} type='error'>{message.msg}</Info>

                <Input label='User ID' type='test' placeholder='Enter ID' errorMessage={PwdError} value={formData.adminId} onChange={(e) => setFormData({ ...formData, adminId: e.target.value })} />
                <Input label='Password' type='password' placeholder='Enter password' errorMessage={IDError} value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
                <Button text='Sign In' onClick={ProcessLogin} loading={btnLoading} />

            </div>
        </div>
    );
}