const eth_usd_abi = require('../aggregators/chainlink/eth_usd.abi.json');
const trx_usd_abi = require('../aggregators/chainlink/trx_usd.abi2.json');
const networks = {
    mainnet: {
        chainId: '0x1', // Ethereum Mainnet
        rpcUrl: 'https://mainnet.infura.io/v3/0c451f302bd54f819c0c7e1e59b4b1dd',
        explorerUrl: 'https://etherscan.io/',//  /tx{transaction_hash} or /address/
        aggregators: {
            chainlink: {
                eth_usd: {
                    abi: eth_usd_abi,
                    address: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
                    apiKey: '',
                    baseUrl: '/',
                },
                trx_usd: {
                    abi: trx_usd_abi,
                    address: '0x307cCF7cBD17b69A487b9C3dbe483931Cf3E1833',
                    apiKey: '',
                    baseUrl: 'https://polygon-rpc.com/',
                }
            },
            etherscan: {
                abi: [],
                address: '0x',
                baseUrl: 'https://api.etherscan.io/api/',
                apiKey: 'URDP6B2IVFT8PM9XX7PZWJ3KJM927GPIZN'
            },
        },
        tron: {
            rpcUrl: 'https://api.trongrid.io/',
            contract: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
             explorerUrl:'https://tronscan.org/#/'// /transaction/{transaction_hash} or /address/{wallet_address}
        },
        bitcoin:{
            rpcUrl: '/',
            network:'main',
            explorerUrl: 'https://blockchair.com/bitcoin/',
            chainId: '0x6f',
        }
    },



    testnet: {
        chainId: '0x1', // Sepolia Testnet
        rpcUrl: 'https://sepolia.infura.io/v3/0c451f302bd54f819c0c7e1e59b4b1dd',
        explorerUrl: 'https://sepolia.etherscan.io/',// /tx/{transaction_hash} or /address/{wallet_address}
        aggregators: {
            chainlink: {
                eth_usd: {
                    abi: eth_usd_abi,
                    address: '0x694AA1769357215DE4FAC081bf1f309aDC325306',
                    apiKey: '',
                    baseUrl: '/',
                },
                trx_usd: {
                    abi: trx_usd_abi,
                    address: '0x307cCF7cBD17b69A487b9C3dbe483931Cf3E1833',//'0xdAeE45A0e2Efa666C3A9E926D422d05A0782f25b',
                    apiKey: '',
                    baseUrl: 'https://polygon-rpc.com/',
                }
            },
            etherscan: {
                abi: [],
                address: '0x',
                baseUrl: 'https://api-sepolia.etherscan.io/api/',
                apiKey: 'URDP6B2IVFT8PM9XX7PZWJ3KJM927GPIZN'
            },
        },
        tron: {
            rpcUrl: 'https://api.shasta.trongrid.io/',
            contract: 'TLiQNkveXsU4fziZpkR4EChuW7Zmr5duDd',
            explorerUrl:'https://shasta.tronscan.org/#/' // /transaction/{transaction_hash} or /address/{wallet_address}
        },
        bitcoin:{
            rpcUrl: '/',
            network: 'test3',
            explorerUrl: 'https://blockchair.com/bitcoin/testnet/',
            chainId: '0x6f',
        }
    }
};

export default networks;
