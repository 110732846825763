import { useEffect, useState } from "react";
import Button from "../components/button";
import Input from "../components/input";
import { FetchBonus, GetPages, UpdateBonus, UpdatePageData } from "../utils/endpoints";
import Info from "../components/info";
import { handleError } from "../utils";

export function AboutPage() {
    const [PageData, setPageData] = useState({});
    const [loading, setLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [info, setInfo] = useState({ type: 'error', message: '', status: false });

    const [formData, setFormData] = useState({
        version: '',
        copyright: '',
        website: ''
    });



    const fetchPageData = () => {
        GetPages().then(({ data: { data } }) => {
            setPageData({ id: data['ID'], ...data['PageData'] });
            const { version, copyright, website } = data.PageData;
            setFormData({
                version: version,
                copyright: copyright,
                website: website
            });

        }).catch((err) => {
            console.log(err);
        });
    }


    useEffect(fetchPageData, []);


    useEffect(() => {
        if (formData.copyright === PageData.copyright && formData?.website === PageData?.website && formData.version === PageData?.version) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    }, [formData.copyright, formData.website, formData.version, PageData?.website, PageData?.version, PageData?.copyright])

    const savePageChanges = () => {
        setLoading(true);
        UpdatePageData(PageData?.id, { content: formData }).then(() => {
            setInfo({ message: 'Page data saved', type: 'success', status: true });
            fetchPageData();
        }).catch((err) => {
            console.log(err);
            setInfo({ message: handleError(err), type: 'error', status: true })
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div>
            <h3 style={{ marginBottom: 40 }}>About Page</h3>
            <Info display={info.status} type={info.type} setDisplay={(st) => setInfo({ ...info, status: st })} autoRemove={true}>{info.message}</Info>
            <Input text="text" label={'Version'} value={formData.version} onChange={(e) => setFormData({ ...formData, version: e.target.value })} />
            <Input text="text" label={'Copyright'} value={formData.copyright} onChange={(e) => setFormData({ ...formData, copyright: e.target.value })} />
            <Input text="text" label={'Website'} placeholder="https://appwebsite.com" value={formData.website} onChange={(e) => setFormData({ ...formData, website: e.target.value })} />
            <Button text="Save Changes" disabled={btnDisabled} loading={loading} onClick={savePageChanges} />
        </div>
    );
}







export function Contact() {
    const [PageData, setPageData] = useState({});
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({ type: 'error', message: '', status: false });

    const [formData, setFormData] = useState({
        whatsapp: '',
        email: '',
        telegram: ''
    });

    const fetchPageData = () => {
        GetPages('contact').then(({ data: { data } }) => {
            setPageData({ id: data['ID'], ...data['PageData'] });
            const { whatsapp, email, telegram } = data.PageData;
            setFormData({
                whatsapp: whatsapp,
                email: email,
                telegram: telegram
            });

        }).catch((err) => {
            console.log(err);
        });
    }


    useEffect(fetchPageData, []);


    useEffect(() => {
        if (formData.email === PageData.email && formData?.telegram === PageData?.telegram && formData.whatsapp === PageData?.whatsapp) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    }, [formData.email, formData.telegram, formData.whatsapp, PageData?.telegram, PageData?.whatsapp, PageData?.email])

    const savePageChanges = () => {
        setLoading(true);
        UpdatePageData(PageData?.id, { content: formData }).then(() => {
            setInfo({ message: 'Page data saved', type: 'success', status: true });
            fetchPageData();
        }).catch((err) => {
            console.log(err);
            setInfo({ message: handleError(err), type: 'error', status: true })
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div>
            <h3 style={{ marginBottom: 40 }}>Contact Page</h3>
            <Info display={info.status} type={info.type} setDisplay={(st) => setInfo({ ...info, status: st })} autoRemove={true}>{info.message}</Info>
            <Input text="text" label={'WhatsApp'} placeholder="Must start with the country code e.g +234 or +44 ..." value={formData.whatsapp} onChange={(e) => setFormData({ ...formData, whatsapp: e.target.value })} />
            <Input text="text" label={'Email'} value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
            <Input text="text" label={'Telegram'} value={formData.telegram} placeholder="@username" onChange={(e) => setFormData({ ...formData, telegram: e.target.value })} />
            <Button text="Save Changes" disabled={btnDisabled} loading={loading} onClick={savePageChanges} />
        </div>
    );
}



export function ActivationBonus() {

    const [PageData, setPageData] = useState({});
    const [formData, setFormData] = useState({ amount: ' ' });
    const [btnDisabled, setButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({ type: 'error', message: '', status: false });


    useEffect(() => {
        FetchBonus().then(({ data: { data } }) => {
            setPageData(data);
            setFormData({ ...formData, amount: data.amount });

        }).catch((err) => {
            console.log(err);
        });
    }, [])

    useEffect(() => {
        if (formData.amount === PageData?.amount) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    }, [formData.amount, PageData?.amount])


    const saveBonusChanges = () => {
        setLoading(true);
        UpdateBonus(PageData?.id, formData).then(() => {
            setButtonDisabled(true);
            setInfo({ message: 'Bonus amount updated successfully', type: 'success', status: true });

        }).catch((err) => {
            setInfo({ message: handleError(err), type: 'error', status: true });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div>
            <h3 style={{ marginBottom: 40 }}> Set activation bonus amount</h3>

            <Info display={info.status} type={info.type} setDisplay={(st) => setInfo({ ...info, status: st })} autoRemove={true}>{info.message}</Info>

            <Input text="text" label={'Amount'} value={formData.amount} onChange={(e) => setFormData({ ...formData, amount: e.target.value })} />
            <Button text="Save Changes" disabled={btnDisabled} loading={loading} onClick={saveBonusChanges} />
        </div>
    );
}