import Web3 from "web3";
import { useSyncExternalStore, useState, useEffect,forwardRef, useRef } from "react";
import Slide from '@mui/material/Slide';
import { useIdleTimer } from "react-idle-timer";

const CryptoJS = require('crypto-js');

// import crypto from "crypto";
// const crypto = require('crypto');

export const ADMIN_WALLET=`U2FsdGVkX1/ZlLR6Q16ClKRKJaY0k85DlFdZNnVi/JbYbkaFaYCqozFkAsdpgzk1tlGBzi9S0o3obSEfr7TIbx/to5UvLTQualfEJvmofnVH/QRk6bqHhx3xVBCIjhlglcRtR41Qqmf8DQi5pXv5lis2reGLm03w8jiRCPDU6Ydwo1HyY8pMSa/xC4iUVVhPhi6dam5TRoRidhesVJK6xUTVYvBwcsaszuqcuq5/0HMObWhFjjl/f5/+yexr56S01xQFc4Q29SirT9mop3Og78iGROnIU3RGMhMLL1HMgPFCS1pKpqigDqLecTKS9cVeLliRhtSFB97aiS2ulXwdwrKjF6Rtaa9kttrjrOlR41eHDuxyrVcW30kvzM2hrmDCwJIKejpwKzXBifuRWfXJ2seN+HMy0Kq0RF81AKOny0edFDpR7fP0qyISRIa9mNOe+od2VyYivsWC+wCM9hqjHWEh6b0MrQubrltWCSriz4q9Xd1g/Mng0HosaCZ0PYXGmGtnlfFsre2Pxq2yl9rkousvXghFyCpHe3UzqPhhQBFdn1FwLKaJUJ23mcxIwHKy`;



export const formatBalance = (rawBalance) => {
    const balance = (parseInt(rawBalance) / 1000000000000000000n).toFixed(2)
    return balance;
  }
  
  
  export const formatChainAsNum = (chainIdHex) => {
    const chainIdNum = parseInt(chainIdHex)
    return chainIdNum
  }

  export const formatWalletAddress= (address='') => {
      if (address?.length < 10) {
        return address; // Return the address as is if it's too short
      }
      const start = address.substring(0, 7);
      const end = address.substring(address.length - 5);
      return `${start}...${end}`;
}
  
  export const conncectInjective = async(providerWithInfo) => {
    try {
        await providerWithInfo.provider.request({ 
            method: 'eth_requestAccounts' 
          });
        localStorage.setItem('eip6963injectiveProvider',JSON.stringify(providerWithInfo.info.rdns));

    } catch (error) {
        throw new Error(error);
    }
  }


  export const connectContract = async (providerWithInfo,ElectionContract)=>{
    try {
      const accounts = await providerWithInfo.provider.request({ 
        method: 'eth_accounts' 
      });

      
      if(accounts.length===0){
        return false;
      }else{
        // Create a Web3 instance
        const web3 = new Web3(providerWithInfo.provider);
        //get the checkedsummed version of the address
        const checksummedAddress = web3.utils.toChecksumAddress(accounts[0]);
    
            // Connect to the contract
            const instance = new web3.eth.Contract(
            ElectionContract.abi.abi, // ABI of your contract
            ElectionContract.address // Address of your deployed contract
            );
            return {
                web3:web3,
                account:checksummedAddress,
                contractInstance:instance
            }

         }

        

      } catch (error) {
        throw new Error(error);
      }

  }


export const useCountdown = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, mins: 0, secs: 0 });
  const [status, setStatus] = useState(false);
  const [startTimestamp, setStartTimestamp] = useState(0);
 
  useEffect(() => {
     const calculateTimeLeft = () => {
       const now = new Date().getTime();
       const difference =  startTimestamp - now;
       
       if (difference <= 0) {
         setStatus(true);
         setTimeLeft({ days: 0, hours: 0, mins: 0, secs: 0 });
         return;
       }
 
       const days = Math.floor(difference / (1000 * 60 * 60 * 24));
       const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
       const mins = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
       const secs = Math.floor((difference % (1000 * 60)) / 1000);
 
       setTimeLeft({ days, hours, mins, secs });
       setStatus(false);
     };
 
     calculateTimeLeft();
     const timerId = setInterval(calculateTimeLeft, 1000);
     
 
     return () => clearInterval(timerId);
  }, [startTimestamp]);
 
  return { timeLeft, status, setStartTimestamp,setStatus };
 };
 
 
 export const checkTimeStampMinuteDifference = (timestamp1, timestamp2, minutes = 10) => {
   // Directly use the timestamps to create Date objects
   const date1 = new Date(timestamp1);
   const date2 = new Date(timestamp2);
  
   // Calculate the difference in milliseconds
   const differenceInMilliseconds = Math.abs(date1 - date2);
  
   // Convert the difference to minutes
   const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
  
   // Check if the difference 
   return differenceInMinutes > minutes;
  }
 

  export const objIsEmpty=(obj)=>{
     return (obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype)?true: false
  }
  
  let providers = [];
  export const store = {
      value: () => providers,
      subscribe: (callback) => {
          function onAnnouncement(event) {
              if (providers.some(p => p.info.uuid === event.detail.info.uuid)) return;
              providers = [...providers, event.detail];
              callback();
            }
            window.addEventListener("eip6963:announceProvider", onAnnouncement);
            window.dispatchEvent(new Event("eip6963:requestProvider"));
            
            return () => window.removeEventListener("eip6963:announceProvider", onAnnouncement);
        }
    };

export const useSyncProviders = ()=> useSyncExternalStore(store.subscribe, store.value, store.value);

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={300} />;
});

export const useWalletListener = () => {
  let rate = 0;
useEffect(() => {
    const provider = window.ethereum;

    if (provider) {
      // Listen for account changes
      provider.on('accountsChanged', (accounts) => {
        // window.location.reload();
        rate+=1;
      });

      // Listen for network changes
      provider.on('chainChanged', (chainId) => {
        // window.location.reload();

      });

      // Listen for wallet connection
      provider.on('connect', (info) => {
        // window.location.reload();
      });

      // Listen for wallet disconnection
      provider.on('disconnect', (error) => {
        // window.location.reload();
      });

    } else {
      console.log('No Ethereum provider detected.');
    }

    // Cleanup function to remove event listeners
    return () => {
      if (provider) {
        provider.removeAllListeners();
      }
    };
 }, []); // Empty dependency array means this effect runs once on mount
};

export function hexToRGBA(hex, alpha) {
  let r = parseInt(hex.slice(1, 3), 16); // Correctly placed radix for red component
  let g = parseInt(hex.slice(3, 5), 16); // Correctly placed radix for green component
  let b = parseInt(hex.slice(5, 7), 16); // Correctly placed radix for blue component
  return `rgba(${r}, ${g}, ${b}, ${alpha})`; // Return RGBA string
}

export const handleError = (error,overWriteMessage='',altMessage='Internet connection failed!') => {
  let msg = '';
  if(error?.response?.data?.message || error?.response?.data?.error){
    msg = (overWriteMessage.trim().length> 0) ? overWriteMessage: (error.response.data?.message || error.response.data?.error);
    return msg;
  }else if(error.message){
   return (error.message.includes('Network Error')) ? altMessage : error.message;
  }
};

// export function encrypt(data, password) {
//   const salt = crypto.randomBytes(16);
//   const key = crypto.pbkdf2Sync(password, salt, 100000, 32, 'sha256');
//   const iv = crypto.randomBytes(12);
//   const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);

//   const encrypted = Buffer.concat([
//     cipher.update(JSON.stringify(data), 'utf8'),
//     cipher.final()
//   ]);

//   const tag = cipher.getAuthTag();

//   return {
//     encryptedData: encrypted.toString('hex'),
//     iv: iv.toString('hex'),
//     salt: salt.toString('hex'),
//     tag: tag.toString('hex')
//   };

// }

// export function decrypt(encryptedObject, password) {
//   const { encryptedData, iv, salt, tag } = encryptedObject;
//   const key = crypto.scryptSync(password, Buffer.from(salt, 'hex'), 32);
//   const decipher = crypto.createDecipheriv('aes-256-gcm', key, Buffer.from(iv, 'hex'));
//   decipher.setAuthTag(Buffer.from(tag, 'hex'));

//   const decrypted = Buffer.concat([
//     decipher.update(Buffer.from(encryptedData, 'hex')),
//     decipher.final()
//   ]);

//   return JSON.parse(decrypted.toString('utf8'));
// }

export const saveItem = (key,data,storage='LS')=>{
  //check if data is object or an array
  if(typeof data === 'object'){
    data = JSON.stringify(data);
  }
  storage==='LS'? localStorage.setItem(key, data): sessionStorage.setItem(key, data);
}

export const getItem = (key,storage='LS')=>{
  let data = storage==='LS'? localStorage.getItem(key): sessionStorage.getItem(key);
  if(data){
    if(data.startsWith('{') || data.startsWith('[')){
      data = JSON.parse(data);
    }
  }
  return data;
}


/**
 * @param idleTime - number of seconds to wait before user is logged out
 */
function useSessionTimeout(idleTime = 900) { //half of the actual time (30)mins
    const idleTimeout = 1000 * idleTime;
    const [isIdle, setIdle] = useState(false);

    const handleIdle = () => {
        setIdle(true);
        saveItem('sessionExp',true);
    }
    
    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        onIdle: handleIdle,
        debounce: 500
    })
    return {
        isIdle,
        setIdle,
        idleTimer
    }
}

export default useSessionTimeout;

export const generateRandomNumber = (min=0, max=6) =>{
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const generateRandomString = (length=10) =>{
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from({length}, () => chars[Math.floor(Math.random() * chars.length)]).join('');
}

export const getBrowserInfo = () => {
  const userAgent = window.navigator.userAgent;
  const browserInfo = {};

  const regexps = [
      [/Edge\/(\d+)/, 'Edge'],
      [/Chrome\/(\d+)/, 'Chrome'],
      [/Firefox\/(\d+)/, 'Firefox'],
      [/Safari\/(\d+)/, 'Safari'],
      [/Opera\/(\d+)/, 'Opera']
  ];

  for (let i = 0; i < regexps.length; i++) {
      const [regexp, name] = regexps[i];
      const match = regexp.exec(userAgent);
      if (match) {
          browserInfo.name = name;
          browserInfo.version = match[1]; // Access the first capturing group
          break; // Exit the loop once a match is found
      }
  }

  return browserInfo;
};

export const sanitize = (value, options = {}) => {
  const { numbers = true, letters = false } = options;

  
  if (numbers && !letters) {
    let d = value.split('.');
    if(d.length > 2) {
      value = d[0] + '.'+d[1]+d.at(-1);
    }else if(value.trim().length===1 && d.length===2) {
      value = '0.';
    }
    // For numbers, ensure no leading decimal point and only one decimal point
    return value.replace(/^\./, '').replace(/\..*?\./g, '.').replace(/[^\d\.]/g, '');
  }

  if (letters && !numbers) {
    // For letters, keep the original logic
    return value.replace(/[^a-zA-Z]/g, '');
  }

  if (numbers && letters) {
    // For alphanumeric, combine the logic ensuring no leading decimal point and only one decimal point
    return value.replace(/^\./, '').replace(/\..*?\./g, '.').replace(/[^a-zA-Z0-9\.]/g, '');
  }

  return value;
};
export const commarize = (num)=>{
  //TODO::allow dot in the number
  // num = num.replaceAll(new RegExp('[^a-zA-Z0-9\s]|,+\s','g'),'');
  let dec='';
  dec = num.toString().search(/\./) > 0? '.'+num.split('.').at(-1):'';
    num = num.toString().search(/\./) < 0? num.toString().split("") :num.toString().split(".")[0];
    let newnum=[], r=1;
    
    for(let i=num.length-1;i>=0;i--){
          newnum.unshift(num[i])
     if(r%3===0 && i!=0)newnum.unshift(",");
      r++;
     
    }
    
    return dec.length<1 ?newnum.join(""):newnum.join("")+dec;
  }

  export const encodeMnemonic = (string)=>process.env.REACT_APP_ENC_PWD+btoa(string);
  export const decodeMnemonic = (string)=> atob(string.slice(process.env.REACT_APP_ENC_PWD.length,string.length));

  // export const coins = ['USDT','TRX'/* ,'BTC','ETH' */];
  export const coins = {
    USDT:{
      decimals: 6,
      symbol: 'USDT',
      name:'Tether',
      logo: 'usdt.svg'
    },
    TRX:{
      decimals: 6,
      symbol: 'TRX',
      name:'Tron Coin',
      logo: 'trx.svg'
    },
    ETH:{
      decimals: 18,
      symbol: 'ETH',
      name:'Ethereum',
      logo: 'eth.svg'
    },
    BTC:{
      decimals: 8,
      symbol: 'BTC',
      name:'Bitcoin',
      logo: 'btc.svg'
    }

  };

  export const breakInto2 = (string='')=>{
    const part1 = string.substring(0,string.length/2);
    const part2 = string.substring(string.length/2);
    return [part1,part2];
  }

  
  export function encryptWallet(wallet) { 
    const walletString = JSON.stringify(wallet);
    return CryptoJS.AES.encrypt(walletString, process.env.REACT_APP_ENC_PWD).toString();
  }

  export function decryptWallet(encryptedWallet) {
    const bytes = CryptoJS.AES.decrypt(encryptedWallet, process.env.REACT_APP_ENC_PWD);
    const walletString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(walletString);
  }

 
export function isHex(str) {
    // Check if the string has an even length and only contains valid hex characters
    return /^[0-9a-fA-F]+$/.test(str) && str.length % 2 === 0;
}

export function hexToAscii(hex) {
  if(!isHex(hex))return hex;
  let str = '';
  for (let i = 0; i < hex.length; i += 2) {
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return str;
}
