import { decryptWallet, encryptWallet, getItem } from '..';
import networks from '../networks';
import axios from "axios";
// import * as tinysecp from 'tiny-secp256k1';

const bitcoin = require('bitcoinjs-lib');
const bip39 = require('bip39');
const { ec: EC } = require('elliptic');
const bs58 = require('bs58');
const tinysecp = require('tiny-secp256k1');
const ECPairFactory = require('ecpair').ECPairFactory;
const ECPair = ECPairFactory(tinysecp);
// const ECPair = {};

const { bitcoin:{rpcUrl,network,explorerUrl} } = networks[process.env.REACT_APP_NETWORK];

export const btc_networks = {
  main: bitcoin.networks.bitcoin, // for mainnet
  test3: bitcoin.networks.testnet
};


// Create an instance of the secp256k1 elliptic curve
const ec = new EC('secp256k1');

// Generate a Bitcoin wallet using elliptic and bitcoinjs-lib
export const createBitcoinWallet= async () => {
  // Step 1: Generate a mnemonic
  const mnemonic = bip39.generateMnemonic(); // Generate mnemonic (BIP39)
  
  // Step 2: Convert mnemonic to seed
  const seed = await bip39.mnemonicToSeed(mnemonic); // Convert mnemonic to seed (BIP39)

  // Step 3: Generate a private key using elliptic
  const privateKeyBuffer = seed.slice(0, 32); // Use the first 32 bytes as the private key
  const keyPair = ec.keyFromPrivate(privateKeyBuffer);

  // Step 4: Get the public key
  const publicKeyBuffer = keyPair.getPublic().encode('array', true); // Compressed public key

  // Step 5: Use bitcoinjs-lib to generate a P2PKH address
  const { address } = bitcoin.payments.p2pkh({
    pubkey: Buffer.from(publicKeyBuffer),
    network: btc_networks[network], // Use 'bitcoin.networks.bitcoin' for mainnet
  });

  // Step 6: Return the wallet details
  const wallet = {
    mnemonic,                     // BIP39 mnemonic
    address,                      // Bitcoin address
    privateKey: privateKeyBuffer.toString('hex'), // Private key in hexadecimal format
  };

  return encryptWallet(wallet);
}



export async function sendBitcoin(toAddress, amountInSatoshis) {

    const w = getItem('__FLASHUSDT_U.WALLET__');
    if (!w) throw new Error('User wallet not found');
    const selected = w.All.filter((t) => t.Type === 'BTC')[0];
    const { privateKey, address: fromAddress } = decryptWallet(selected.Wallet);

    const baseURL= network === 'test3' ? 'https://blockstream.info/testnet/api/': 'https://blockstream.info/api/';

    

    const utxoResponse = await axios.get(`${baseURL}address/${fromAddress}/utxo`);
    const utxos = utxoResponse.data;

    if (utxos.length === 0) {
      throw new Error('No UTXOs available for this address');
    }

    const server = btc_networks[network]; // Replace 'testnet' with 'mainnet' if you're using mainnet

    // Create a new Bitcoin transaction builder
    const psbt = new bitcoin.Psbt({ network: server });

    let totalInput = 0;


    // Add UTXOs as inputs to the transaction
    for (const utxo of utxos) {
      const rawTxResponse = await axios.get(`${baseURL}tx/${utxo.txid}/hex`);
      const rawTxHex = rawTxResponse.data;

      // Use nonWitnessUtxo for legacy P2PKH inputs
      psbt.addInput({
        hash: utxo.txid,
        index: utxo.vout,
        nonWitnessUtxo: Buffer.from(rawTxHex, 'hex'), // Required for legacy (non-SegWit) inputs
      });
      totalInput += utxo.value;
    }

    

    // Calculate the fee and change
    const fee = 1000; // Set a fixed fee (in satoshis) or calculate dynamically
    const change = totalInput - amountInSatoshis - fee;

    if (change < 0) { 
      throw new Error('Insufficient funds');
    }

    // Add the recipient output
    psbt.addOutput({
      address: toAddress,
      value: amountInSatoshis, // Amount to send in satoshis
    });

    // Add the change output (back to the sender)
    psbt.addOutput({
      address: fromAddress,
      value: change, // Change after deducting amount and fee
    });

    // Use elliptic to create key pair for signing
    const keyPair = ec.keyFromPrivate(privateKey, 'hex');
    const privateKeyBuffer = Buffer.from(keyPair.getPrivate('hex'), 'hex');
    

    // Sign each input using the private key
    utxos.forEach((_, index) => {
      psbt.signInput(index, ECPair.fromPrivateKey(privateKeyBuffer, { network: server }));
    });

    // Finalize all inputs
    psbt.finalizeAllInputs();

    // Extract the raw transaction in hexadecimal format
    const rawTransaction = psbt.extractTransaction().toHex();

    // Broadcast the transaction to the network
    const broadcastResponse = await axios.post(`${baseURL}tx`, rawTransaction);
    
    return broadcastResponse.data; // Return the transaction ID (txid)
  
}





// Function to derive the public key from the private key using Elliptic
const derivePubKey = (privateKeyHex, base58=true) => {
  const keyPair = ec.keyFromPrivate(privateKeyHex);

  // Derive public key (compressed format)
  const publicKey = keyPair.getPublic(true, 'hex');

  // Convert it to a Buffer, since Bitcoin libraries often expect Buffers
  const publicKeyBuffer = Buffer.from(publicKey, 'hex');

  return (base58)?bs58.encode(publicKeyBuffer) : publicKeyBuffer;
};


export function isBtcAddress(address) {
  try {
    bitcoin.address.toOutputScript(address, btc_networks[network]);
    return true; // Valid address
  } catch (e) {
    return false; // Invalid address
  }
}
