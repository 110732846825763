import React, { memo, useCallback, useEffect, useState } from 'react';
import Button from '../components/button';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from '../components/nav';
import Head from '../components/header';
import { useTheme } from '@emotion/react';
import Input from '../components/input';
import { coins, commarize, decryptWallet, getItem, handleError, hexToAscii, sanitize } from '../utils';
import { checkTRC20Balance, checkTrc20TransactionStatus, checkUSDTBalance, formattedPrivateKey, isTronAddress, sendTrc20, sendTrx } from '../utils/tron/tron';
import Info from '../components/info';
import ConfirmTxn from './confirmTxn';
import Spinner from '../components/spinner';
import PinBox from '../components/pinBox';
import Flyout from '../components/flyout';
import Popup from '../components/popup';
import nope from '../images/gradient-hate-emoji-illustration 1.svg';
import { checkERC20Balance, isEthAddress, sendTransaction } from '../utils/usdt/usdt';
import txDetail from './txDetail';
import { QrReader } from 'react-qr-reader';
import QRScanner from '../components/scanner';
import { isBtcAddress, sendBitcoin } from '../utils/bitcoin';
import { getBTCBalance } from '../utils/endpoints';


const md5 = require('md5');


export default function InitCoinSending(){
  const [Page,setPage] = useState({id:3,data:null});
  return(

    Page.id === 3 ? <TxDetail CTA={setPage}/>:
    Page.id === 2 ? <ConfirmTxn CTA={setPage} TxData={Page.data}/>:
    Page.id === 1 ? <AuthoriseTx CTA={setPage} TxData={Page.data}/>:
    <TxSendingScreen CTA={setPage} TxData={Page.data}/>

  )

}


const useStyles = makeStyles((theme) => ({
  root:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection:'column',
    height: 'calc(100dvh - 190px)',
    paddingTop: theme.spacing(1), width:'100%',
    
    color: theme.palette.text.primary,fontSize: 14, //confectionary
    '& .inputs':{
      width:'100%',
      maxWidth:'500px',
    },
    '& .msg':{
     margin:'-15px 0 10px', fontSize:'12px', color: theme.palette.text.primary,
     display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap:5,
     color: theme.palette.text.secondary,
    },
    '& .btn':{
      height:60, width:'100%', display:'flex',justifyContent: 'center',alignItems: 'center',
    },
  },
   
  
}));


export const TxDetail =({CTA})=>{
  const classes = useStyles();
  const nav = useNavigate();
  const [chainInfo,setChainInfo] = useState({network:'',fee:'',icon:'caution.svg'});
  const [info,setInfo] = useState({type:'error',message:'',status:false});

  const [formData,setFormData] = useState({addr:'',amt:''});
  const [addrError,setAddrError] = useState('');
  const [amtError,setAmtError] = useState('');
  const [selectedCoin,setSelectedCoin] = useState('');
  const [inlineLoading,setInlineLoading] = useState(false);
  const [btnLoading,setBtnLoading] = useState(false);
  const [btnDisabled,setBtnDisabled] = useState(false);
  const {coin} = useParams();
  

  useEffect(()=>{
    if(coins[coin.toUpperCase()]){
      setSelectedCoin(coins[coin.toUpperCase()]);
    }else{
      nav('/dashboard/coins');
    }
  },[])

  const ScanIcon = ()=>(<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M5.25 12.5C5.25 8.77208 8.27208 5.75 12 5.75C12.4142 5.75 12.75 6.08579 12.75 6.5C12.75 7.74264 13.7574 8.75 15 8.75C15.5075 8.75 15.9738 8.58287 16.3499 8.30033C16.5089 8.18083 16.7089 8.12941 16.9059 8.15738C17.1028 8.18536 17.2806 8.29043 17.4001 8.44949C18.2475 9.57771 18.75 10.9813 18.75 12.5C18.75 12.9142 18.4142 13.25 18 13.25H6C5.58579 13.25 5.25 12.9142 5.25 12.5Z" fill="#141B34"/>
    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M6.47909 14.8711C6.93768 14.6222 7.52449 14.7692 7.78976 15.1995C8.62052 16.5468 10.1709 17.4501 11.945 17.4501C13.7191 17.4501 15.2695 16.5468 16.1002 15.1995C16.3655 14.7692 16.9523 14.6222 17.4109 14.8711C17.8695 15.12 18.0262 15.6705 17.7609 16.1008C16.6013 17.9814 14.4316 19.25 11.945 19.25C9.45836 19.25 7.28866 17.9814 6.12908 16.1008C5.8638 15.6705 6.02051 15.12 6.47909 14.8711Z" fill="#141B34"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.18557 2.95052C9.2129 3.50213 8.7879 3.97146 8.23629 3.9988C6.16432 4.10148 5.19943 4.40811 4.55377 5.05377C3.90811 5.69943 3.60148 6.66432 3.4988 8.73629C3.47146 9.2879 3.00213 9.71291 2.45052 9.68557C1.89892 9.65823 1.47391 9.1889 1.50125 8.6373C1.60669 6.50982 1.92267 4.85645 3.13956 3.63956C4.35645 2.42267 6.00982 2.10669 8.13729 2.00125C8.6889 1.97391 9.15823 2.39892 9.18557 2.95052ZM14.8145 2.95052C14.8418 2.39892 15.3111 1.97391 15.8628 2.00125C17.9902 2.10669 19.6436 2.42267 20.8605 3.63956C22.0774 4.85645 22.3934 6.50982 22.4988 8.6373C22.5261 9.1889 22.1011 9.65823 21.5495 9.68557C20.9979 9.71291 20.5286 9.2879 20.5012 8.73629C20.3986 6.66432 20.0919 5.69943 19.4463 5.05377C18.8006 4.40811 17.8357 4.10148 15.7638 3.9988C15.2121 3.97146 14.7871 3.50213 14.8145 2.95052ZM2.45052 15.3145C3.00213 15.2871 3.47146 15.7121 3.4988 16.2638C3.60148 18.3357 3.90811 19.3006 4.55377 19.9463C5.19943 20.5919 6.16432 20.8986 8.23629 21.0012C8.78789 21.0286 9.2129 21.4979 9.18556 22.0495C9.15822 22.6011 8.68889 23.0261 8.13729 22.9988C6.00982 22.8934 4.35645 22.5774 3.13956 21.3605C1.92267 20.1436 1.60669 18.4902 1.50125 16.3628C1.47391 15.8111 1.89892 15.3418 2.45052 15.3145ZM21.5495 15.3145C22.1011 15.3418 22.5261 15.8111 22.4988 16.3628C22.3934 18.4902 22.0774 20.1436 20.8605 21.3605C19.6436 22.5774 17.9902 22.8934 15.8628 22.9988C15.3111 23.0261 14.8418 22.6011 14.8145 22.0495C14.7871 21.4979 15.2121 21.0286 15.7638 21.0012C17.8357 20.8986 18.8006 20.5919 19.4463 19.9463C20.0919 19.3006 20.3986 18.3357 20.5012 16.2638C20.5286 15.7121 20.9979 15.2871 21.5495 15.3145Z" fill="#141B34"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 12.5C1.5 11.9477 1.94772 11.5 2.5 11.5H21.5C22.0523 11.5 22.5 11.9477 22.5 12.5C22.5 13.0523 22.0523 13.5 21.5 13.5H2.5C1.94772 13.5 1.5 13.0523 1.5 12.5Z" fill="#141B34"/>
    </svg>);

  //validate inputs and proceed
  const processRequet = useCallback(()=>{
    const userWallet = getItem('__FLASHUSDT_U.WALLET__');
    if(!userWallet){
      nav('/signup');
      return
    }
    const {Main, All} = userWallet;
    const selected = (coin.toUpperCase() === 'USDT')? Main: All.filter((t)=> t.Type === coin.toUpperCase())[0];
    const wallet = decryptWallet(selected.Wallet);
    

    switch (coin) {
      case 'usdt':
          if(!isTronAddress(formData.addr)) {
            setAddrError('Please enter a valid wallet address');
            return;
          }
          setAddrError('');

          if(formData.amt.trim().length === 0 || +formData.amt < 1) {
            setAmtError('Please enter a valid amount');
            return;
          }
          setAmtError('');

          setBtnDisabled(true);
          setInlineLoading(true);
          checkUSDTBalance(wallet.address, wallet.privateKey).then((amount) =>{
            if(amount < parseFloat(formData.amt)){
              setAmtError('Insufficient balance');
              setInlineLoading(false);
              setBtnDisabled(false);
              return;
            }
            setAmtError('');
            checkTRC20Balance(wallet.address).then(({token}) =>{
              if(token < 10){
                setInfo({
                  type:'error',
                  status:true,
                  message:'Oops.., you do not have enough TRX tokens to pay for transaction fee'
                });
                return;
              }

              //TODO:: Proceeed here...
              Proceed(wallet);

            }).catch((err) =>{
            setAmtError(handleError(err));

            }).finally(() =>{
              setInlineLoading(false);
              setBtnDisabled(false);
            });

          }).catch((err)=>{
            setAmtError(handleError(err));
          })

      break;
      case 'trx':
        if(!isTronAddress(formData.addr)) {
          setAddrError('Please enter a valid wallet address');
          return;
        }
        setAddrError('');
        if(formData.amt.trim().length === 0 || +formData.amt === 0) {
          setAmtError('Please enter a valid amount');
          return;
        }
        setAmtError('');
        
        setBtnDisabled(true);
        setInlineLoading(true);

        checkTRC20Balance(wallet.address).then(({token}) =>{
          if(+token < parseFloat(formData.amt)){
            setAmtError('Insufficient balance');
            return;
          }
          setAmtError('');

          
          Proceed(wallet);

        }).catch((err) =>{
          console.log(err);

        setAmtError(handleError(err));

        }).finally(() =>{
          setInlineLoading(false);
          setBtnDisabled(false);
        });

        break;
        case 'eth':
          if(!isEthAddress(formData.addr)){
            setAddrError('Please enter a valid wallet address');
            return;
          }
          setAddrError('');

          if(formData.amt.trim().length === 0 || +formData.amt === 0) {
            setAmtError('Please enter a valid amount');
            return;
          }
          setAmtError('');

          setBtnDisabled(true);
          setInlineLoading(true);

          checkERC20Balance(wallet.address).then(({token}) =>{
            if(+token < parseFloat(formData.amt)){
              setAmtError('Insufficient balance');
              return;
            }
            setAmtError('');
            Proceed(wallet);
  
          }).catch((err) =>{
            console.log(err);
  
          setAmtError(handleError(err));
  
          }).finally(() =>{
            setInlineLoading(false);
            setBtnDisabled(false);
          });


          break;

        case 'btc':
          if(!isBtcAddress(formData.addr)){
            setAddrError('Please enter a valid wallet address');
            return;
          }          
          setAddrError('');

          if(formData.amt.trim().length === 0 || +formData.amt === 0) {
            setAmtError('Please enter a valid amount');
            return;
          }
          setAmtError('');

          setBtnDisabled(true);
          setInlineLoading(true);

          getBTCBalance(wallet.address).then(({token}) =>{
            if(+token < parseFloat(formData.amt)){
              setAmtError('Insufficient balance');
              return;
            }
            setAmtError('');
            Proceed(wallet);
  
          }).catch((err) =>{
            console.log(err);
  
          setAmtError(handleError(err));
  
          }).finally(() =>{
            setInlineLoading(false);
            setBtnDisabled(false);
          });


          break;
    }

  },[formData.addr,formData.amt]);

  const color = info.type === 'error'?'red':'#116EFC';

  const Proceed = (wallet)=>{
    const user = getItem('__FLASHUSDT_USER__');
    if(!user){
      nav('/signup');
      return
    }

    const payload ={
      coin: `${coins[coin.toUpperCase()].name} (${coins[coin.toUpperCase()].symbol.toUpperCase()})`,
      from:`${user.FirstName} ${user.Surname}`,
      to:formData.addr,
      amount:formData.amt,
      coinSymbol:coins[coin.toUpperCase()].symbol,
      privateKey: wallet.privateKey
    } 
    //call
    CTA({id:2,data:payload});
  }
  

  return (
    <div style={{paddingTop:60, display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column', width:'100%'}}>
     <Head backTo='/dashboard' currentPageTitle={`Send ${selectedCoin?.name} (${selectedCoin?.symbol}) `}/>

     <Info display={info.status} autoRemove={true} setDisplay={(st)=>setInfo({...info,status:st})} type={info.type} style={{fontSize:12, border:'1px solid '+color}}>{info.message}</Info>

      <div className={classes.root}> 
      <div className="inputs">
       
          <Input label='Receiver wallet address' placeholder='Paste wallet address' value={formData.addr} onChange={(e)=>setFormData({...formData,addr:e.target.value})} errorMessage={addrError}
          icon={ <span className='hide' tabIndex={-1} role='button' onClick={()=>null}><ScanIcon/></span>}/>
        
        
        {/* <p className='msg'>
          {inlineLoading && 'Network: '}

          {!inlineLoading ?
           chainInfo.network.length > 1 && <>
            Network: {chainInfo.network} <img src={`/${chainInfo.icon}`}/>
           </> :
        <img src={`/svg-spinners-3-dots.svg`} alt=' ' id='icon'  width={21} /> }

        </p> */}

        <Input label='Amount' placeholder='Enter amount to send' showIcon={false} value={formData.amt} onChange={(e)=>setFormData({...formData,amt:commarize(sanitize(e.target.value))})} errorMessage={amtError}/>      
        <p className='msg'>{inlineLoading && <img src={`/svg-spinners-3-dots.svg`} alt=' ' id='icon'  width={21} /> }</p>     
      </div>
  <div className='btn'>
    <Button disabled={btnDisabled} text='Next' onClick={processRequet}/>
  </div>

    </div>
      
    </div>
  );
}


const useStyles2 = makeStyles((theme) => ({
  root: {
      display: 'flex', marginTop: '60px',
      flexDirection: 'column',
      alignItems: 'center', marginTop:'5rem',
      '& .full-width':{
          width: '100%', display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent: 'center',
      }
  },
  titleSection: {
      textAlign: 'center',
      marginBottom: theme.spacing(6),
      '& h3': {
          color: theme.palette.text.primary,
      },
      '& p': {
          color: theme.palette.text.secondary,
          padding: theme.spacing(1, 2),
          fontSize: '14px',
      },
  },
  loginSection: {
      marginTop: theme.spacing(10),color: theme.palette.text.primary,
      '& a': {
          color: theme.palette.primary.main,
          textDecoration: 'none',
          fontSize: '14px',
          fontWeight: 'bold',
      },
  },
  nav:{
    color: theme.palette.primary.main,
    cursor: 'pointer', fontWeight:500,
  }
}));


//-------------------------------------------
const AuthoriseTx= ({CTA,TxData})=>{
  const classes = useStyles2();
  const nav = useNavigate();

  const [info, setInfo] = useState({type:'error', message:'',status:false});
  const [pinValue, setPinValue] = useState('');

  useEffect(() => {
      const uid = getItem('__FLASHUSDT_USER__');
      if(!uid) nav('/signup');

      if(pinValue.length ===4) {
          proceedSubmission(uid);
      }
      
  },[pinValue]);

  const proceedSubmission = useCallback( async (uid) => {        
    if(md5(pinValue) === uid.TxnPin){
      CTA({id:0,data:TxData});
    }else{
      setInfo({...info,status:true, message:'Invalid PIN'});
    }   
  },[pinValue]);

  return (
      <>
           <Head currentPageTitle='' showArrow={false}>
           <span className={classes.nav} tabIndex={-1} role='button' onClick={()=>nav(`/dashboard/coin-view/${TxData.coinSymbol.toLowerCase()}`)}>Cancel</span>
           </Head>

          <div className={classes.root}>
          
              <div className={classes.titleSection}>
                  <h3>Authorize Transaction</h3>
                  <p>Kindly enter your 4-digit secure pin to authorize this transaction</p>
              </div>

          <Info type={info.type} autoRemove={true} display={info.status} style={{fontSize:12, border:'1px solid red'}} setDisplay={(st)=>setInfo({...info,status:st})}>{info.message}</Info>

              <div>
                  <PinBox getValue={setPinValue}/>
              </div>
          </div>
       
      </>
  );

}




//-------------------------------------------

const TxSendingScreen= ({CTA,TxData})=>{
  const [showLoader, setShowLoading] = useState(true);
  const [showFlyout, setShowFlyout] = useState(false);
  const nav = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const theme = useTheme();
  const [exception, setException] = useState('');
  const [txDetails, setTxDetails] = useState({id:'',coin:''});
  const [state, setState] = useState(null);

  useEffect(() => {
    let transactionInitiated = false;
    (async () => {
        try {
          if(!transactionInitiated && TxData.coinSymbol==='USDT'){
            transactionInitiated = true;
            const result = await sendTrc20(TxData.to, sanitize(TxData.amount), TxData.privateKey);
            const res = await checkTrc20TransactionStatus(result);
           if(res?.receipt?.result==='SUCCESS'){
              setShowLoading(false);
              setShowFlyout(true);
           }else{
            setException("Transaction failed!");   
            setShowLoading(false);
            setShowPopup(true);
          }
          console.log(result);
            
          }

          //TRX
          if(!transactionInitiated && TxData.coinSymbol === 'TRX'){
            transactionInitiated = true;
          sendTrx(TxData.to, sanitize(TxData.amount), formattedPrivateKey(TxData.privateKey)).then(res =>{            
            if(res.result){
              setTxDetails({id:res.txid,coin:TxData.coinSymbol.toLowerCase()});
              res.transaction['ret'] = [{
                "contractRet": "SUCCESS",
                "fee": 1100000
            }];

              setState(res.transaction);
              
              setShowLoading(false);
              setShowFlyout(true);
            }else if(res?.code.includes('ERROR')){
              setShowPopup(true);
              setException(hexToAscii(res.message));
              setShowLoading(false);
            }

          }).catch(err =>{
            setException(handleError(err)); 
            
          })
          }

          //ETH
          if(!transactionInitiated && TxData.coinSymbol === 'ETH'){
            transactionInitiated = true;
            const res =  await sendTransaction(TxData.to, sanitize(TxData.amount),ethSuccess);
          }


          //BTC
          if(!transactionInitiated && TxData.coinSymbol === 'BTC'){
            transactionInitiated = true;
            const amount = parseFloat(sanitize(TxData.amount)) * 100_000_000;            
            const res =  await sendBitcoin(TxData.to, Math.floor(amount));
            if(res){
              setTxDetails({id:res, coin:TxData.coinSymbol.toLowerCase()});
              setShowFlyout(true);
              setShowLoading(false);
            }
          }
          

        } catch (err) {
          setShowLoading(false);
          setShowPopup(true);
          console.log(err);
          
          setException(err?.message || "Can't perform your request at this time. Please try again later.");          
        }
    })();
    
  }, [TxData]);

  const ethSuccess = (rec)=>{
    setTxDetails({id:rec.transactionHash,coin:TxData.coinSymbol.toLowerCase()});
    setShowFlyout(true);
    setShowLoading(false);
  }
    
  const viewReceipt = useCallback(()=>{
    if(state){
      return nav(`/dashboard/txn-detail?coin=${txDetails.coin}`,{state:{tx:state}});
    }else{
      return nav(`/dashboard/txn-detail?coin=${txDetails.coin}&tx=${txDetails.id}`);
    }
  },[txDetail.id, state])

  return(

    <>
    {showLoader ? <Spinner/>:
      showFlyout ? (
          <Flyout title="Sent" descr={<span>Your transaction is in progress.<br/> Verification may take a few minutes</span>}>
              {TxData.coinSymbol==='USDT'?<Button text='Back to dashboard' onClick={()=>nav('/dashboard')}/>:<Button text='View Receipt' onClick={viewReceipt}/>}
          </Flyout>
      ):null
      }
      <Popup display={showPopup} setDisplay={setShowPopup}>
      <img src={nope} alt="No tx illustration" />
      <div style={{fontSize:12,fontWeight:400,textAlign:'center', margin:'15px 0',color:theme.palette.text.secondary}}>{exception}</div>

      <Button text='Back to dashboard' onClick={()=>nav('/dashboard')}/>
  
    </Popup>
    </>

  )


  
}
