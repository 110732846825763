const webpush = require('web-push');

document.body.insertAdjacentHTML("afterbegin", `<div class="no-desktop">
    <img src="/alert-02.svg" width="300"/>
    <br/>
    <p>
    ...Oops! this app is not yet available on desktop. <br/> Check back later.
    </p>
    </div>`); 


export const pubkey = 'BJC1RFufJIr_KZkfBkKn8RB03NFiCVY2ue2GSp6yZWo2gZZxwQxhBqE5YAI4pJ32hhHMA6aHtthMDFhEGgojRik';
export const privatekey = 'TP0YaEWAHZFNDhTCnVQWCs2qP7G94nknB68RIFr7iNI';


    
    function urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
      const rawData = window.atob(base64);
      return new Uint8Array([...rawData].map((char) => char.charCodeAt(0)));
    }