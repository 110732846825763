import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import { getItem, hexToRGBA } from '../../utils';
import { globals } from '../../utils/themes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { GetPages } from '../../utils/endpoints';
import Spinner from '../../components/spinner';






const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary, marginTop: 20,
        display: 'flex', flexDirection: 'column', gap: 20,
        '& a': {
            textDecoration: 'none', color: theme.palette.text.primary
        }
    },
    setting: {
        display: 'flex', alignItems: 'center',
        justifyContent: 'space-between', width: '100%', backgroundColor: theme.palette.primary.tintMain,
        padding: '20px 20px', borderRadius: globals.borderRadius, marginBottom: 15,

        '& span': {
            color: theme.palette.text.primary, fontWeight: 500, fontSize: 13,
        },
        '& p': {
            color: theme.palette.text.secondary, fontSize: 11, padding: '4px 0'
        },
        '& .left': {
            alignItems: 'center', justifyContent: 'space-between', gap: 10, display: 'flex',
            '& .icon-cont': {
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                padding: '7px', borderRadius: '20px', backgroundColor: hexToRGBA(theme.palette.background.default, 0.3)
            }
        },

    }


}));




export default memo(function About() {
    const classes = useStyles();
    const [loading,setLoading] = useState(true);
    const [formData, setFormData] = useState({
        version: '',
        copyright: '',
        website: ''
    });

    useEffect(() => {
        GetPages().then((res) => {
            if (res?.data?.data) {
                const { version, copyright, website } = res.data.data.PageData;
                setFormData({
                    version: version,
                    copyright: copyright,
                    website: website
                });
                setLoading(false);
            }

        }).catch((err) => {
            console.log(err);
        });
    }, [])

    return (
        <div style={{ paddingTop: 36 }}>
            <Head backTo='/dashboard/settings' currentPageTitle='About' />
        {loading && <Spinner/>}
            <div className={`${classes.root}`}>

                <div>
                    Version <br />
                    <span>{formData.version}</span>
                </div>

                <div>
                    Copyright <br />
                    <span>{formData.copyright}</span>
                </div>

                <a href={formData.website} target='_blank'>
                    <span>Developers website </span>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>



            </div>
        </div>
    );


});