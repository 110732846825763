import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Dashboard from "./modules/dasboard";
import StartScreen from "./modules/startScreen";
import EmailScreen from "./modules/boarding";
import { darkTheme, lightTheme } from "./utils/themes";
import { ThemeProvider } from "@mui/material";
import EmailVerification from "./modules/verifyEmail";
import TxnPin from "./modules/txnPin";
import SignIn from "./modules/signin";
import ResetPasswordFlows from "./modules/resetPassword";
import Profile from "./modules//settings/profile";
import Notification from "./modules/notification";
import Settings from "./modules/settings";
import AccountActivation from "./modules/settings/accountStatus";
import ReCreatePassword from "./modules/settings/createNewPassword";
import SecuritySettings from "./modules/settings/security";
import CustomerSupport from "./modules/settings/customerSupport";
import SecretPhrase from "./modules/settings/secretPhrase";
import Nav from "./components/nav";
import ProfileSetup from "./modules/profileSetup";
import ImportWallet from "./modules/settings/importWallet";
import PluginUpload from "./modules/settings/uploadPlugin";
import PersonalServerActivation from "./modules/settings/personalServerActivation";
import { getItem } from "./utils";
import TxnHistory from "./modules/txnHistory";
import WalletCard from "./components/walletCard";
import CoinLists from "./modules/coinLists";
import CoinView from "./modules/coinView";
import TxDetail from "./modules/txDetail";
import WalletAddress from "./modules/walletAddr";
import InitCoinSending from "./modules/sendCoin";
import About from "./modules/settings/about";
import { Admin } from "./admin";

function App() {
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {

    // Remove any existing iframe elements (from ads or other sources)
    // document.querySelector('iframe')?.remove();

    //gett user theme from storage
    const storedTheme = getItem('__FLASHUSDT_U.THEME__');
    if (storedTheme) {
      setTheme((storedTheme === 'dark' ? darkTheme : lightTheme));
    }

    // Set the body background color based on the current theme
    document.body.style.backgroundColor = theme.palette.background.default;
    //redirect user that did not login back to login
    // check if they attemt too get into dashboard pathname without signing in
    if (window.location.pathname.includes('dashboard')) {
      const userData = getItem('__FLASHUSDT_USER__');
      if (!userData) {
        window.location.href = '/signin';
        return;
      }
      if (+userData.LoggedIn !== 1) {
        window.location.href = '/signin';
      }
    }


    const preventTouchMove = (event) => {
      event.preventDefault();
    };
  
    document.addEventListener('touchmove', preventTouchMove, { passive: false });

    // Set scrollRestoration to 'manual' if supported
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }

  // Define the beforeunload event handler
  const handleBeforeUnload = (event) => {
    document.body.style.overscrollBehaviorY = 'none';
  };

  // Add the beforeunload event listener
  window.addEventListener('beforeunload', handleBeforeUnload);

  // Cleanup function to remove the beforeunload event listener
  // Cleanup function to remove the event listener
  return () => {
    document.removeEventListener('touchmove', preventTouchMove);
    window.removeEventListener('beforeunload', handleBeforeUnload);

    // Optionally reset scrollRestoration if needed
    // if ('scrollRestoration' in history) {
    //   history.scrollRestoration = 'auto';
    // }
  };

  }, [theme]);




  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/' element={<StartScreen />} />
        <Route path='signup' element={<EmailScreen />} />
        <Route path='signup/verify' element={<EmailVerification />} />
        <Route path='signup/profile' element={<ProfileSetup />} />
        <Route path='signup/txnpin' element={<TxnPin />} />
        <Route path='forget-password' element={<ResetPasswordFlows />} />
        <Route path='signin' element={<SignIn />} />
        <Route path='dashboard/*' element={<Nav />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='dashboard/profile' element={<Profile />} />
        <Route path='dashboard/notification' element={<Notification />} />
        <Route path='dashboard/settings' element={<Settings setUserTheme={setTheme} />} />
        <Route path='dashboard/activation' element={<AccountActivation />} />
        <Route path='dashboard/reset-password' element={<ReCreatePassword />} />
        <Route path='dashboard/security' element={<SecuritySettings />} />
        <Route path='dashboard/support' element={<CustomerSupport />} />
        <Route path='dashboard/about' element={<About />} />
        <Route path='dashboard/phrase' element={<SecretPhrase />} />
        <Route path='dashboard/wallet-import' element={<ImportWallet />} />
        <Route path='dashboard/server-import' element={<PersonalServerActivation />} />
        <Route path='dashboard/txn/:coin' element={<TxnHistory />} />
        <Route path='dashboard/coins' element={<CoinLists />} />
        <Route path='dashboard/coin-view/:coin' element={<CoinView />} />
        <Route path='dashboard/txn-detail' element={<TxDetail />} />
        <Route path='dashboard/send/:coin' element={<InitCoinSending />} />
        <Route path='dashboard/receive/:coin' element={<WalletAddress />} />
        <Route path='dashboard/plugin' element={<PluginUpload />} />
        <Route path='admin' element={<Admin />} />
        <Route path='admin/pages/about' element={<Admin />} />
        <Route path='admin/pages/contact' element={<Admin />} />
        <Route path='admin/pages/activation-bonus' element={<Admin />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
