import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Button from '../components/button';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Nav from '../components/nav';
import Usercard from '../components/usercard';
import MainBalanceCard from '../components/balanceCard';
import TxnCard from '../components/txnCard';
import Info from '../components/info';
import { coins, decryptWallet, getItem, handleError, saveItem } from '../utils';

import Head from '../components/header';
import WalletCard from '../components/walletCard';
import { checkTRC20Balance, checkUSDTBalance, createTRC20Wallet } from '../utils/tron/tron';
import Spinner from '../components/spinner';
import { checkERC20Balance, createEthereumWallet, USD } from '../utils/usdt/usdt';
import { CreateWalletSnapShot, getBTCBalance } from '../utils/endpoints';
import { createBitcoinWallet } from '../utils/bitcoin';

const useStyles = makeStyles((theme) => ({
  root:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'column',
    height: 'calc(100% - 50px)',
    padding: theme.spacing(1), width:'100%',
    '& a':{
      textDecoration: 'none',
      color: theme.palette.primary.main, fontWeight:600,
    }
  },
  txn: {
   
    display: 'flex',justifyContent: 'center', alignItems: 'flex-start',
    flexDirection: 'column', width:'100%', paddingBottom: theme.spacing(10),
   
  
  }
}));

export default memo(function CoinLists(){
  const classes = useStyles();
  const nav = useNavigate();
  const [userWallet, setUserWallet] = useState({});
  const [userWallet_Raw, setUserWallet_Raw] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const WalletObj = useRef({});
const counter = useRef(0);
  
  useEffect(() => {
    const {All} = getItem('__FLASHUSDT_U.WALLET__');
    if(All){
      setUserWallet_Raw(All);

      All.forEach(({Wallet,Type}) => {
         const {address,privateKey} = decryptWallet(Wallet);
        if(Type === "USDT"){
          checkUSDTBalance(address, privateKey).then((res) =>{
            WalletObj.current[Type] = {address,token:res,rate:1,usd:1*res};
            updateUserWallet();
          })

        }

        if(Type === "TRX"){
          if(counter.current === 0){
            checkTRC20Balance(address).then((res) =>{
             WalletObj.current[Type] = {address,...res};
             updateUserWallet();
             })
          }
          counter.current+=1;
        }


        if(Type === "ETH"){
          checkERC20Balance(address).then((res)=>{
            WalletObj.current[Type] = {address,...res};
            updateUserWallet();
          })
        }

        if(Type === "BTC"){
          getBTCBalance(address).then((res)=>{
            WalletObj.current[Type] = {address,...res};
            updateUserWallet();
          })

        }
         
        });
      }

    },[]);



    const updateUserWallet = useCallback(() => {
      
      setUserWallet(prevState => ({
        ...prevState,...WalletObj.current
      }));
      let s = Object.entries(WalletObj.current);
      
      if(s.length >= userWallet_Raw.length){
        setShowLoader(false);
      }
    }, [WalletObj.current,userWallet_Raw]);

    // console.log(userWallet);
    
    const checkCoinExsistance = useCallback(async(coin) => {
        const uid = getItem('__FLASHUSDT__');

        const {All,Main} = getItem('__FLASHUSDT_U.WALLET__');
        let userWallet = (coin.toUpperCase() === 'USDT')? Main: All.filter((t)=> t.Type === coin.toUpperCase())[0];
  
        if(userWallet){          
          nav(`/dashboard/coin-view/${coin.toLowerCase()}`,{state:{prevRoute:'/dashboard/coins'}});
        }else{
          switch (coin) {
            case 'ETH':
              const encryptedWalletString = createEthereumWallet();
              const payload = {
               Wallet:encryptedWalletString,
               Type:coin
              };
               CreateWalletSnapShot(uid,payload).then((res)=>{
                   saveItem('__FLASHUSDT_U.WALLET__',res.data.data);
                   nav(`/dashboard/coin-view/${coin.toLowerCase()}`,{state:{prevRoute:'/dashboard/coins'}});
               }).catch((e)=>{
                   console.log(handleError(e));
               })
              
              break;
              
            case 'TRX':
                const payload_trc = {
                  Wallet:createTRC20Wallet(),
                  Type:coin
                };
                CreateWalletSnapShot(uid,payload_trc).then((res)=>{
                    saveItem('__FLASHUSDT_U.WALLET__',res.data.data);
                    nav(`/dashboard/coin-view/${coin.toLowerCase()}`,{state:{prevRoute:'/dashboard/coins'}});
                }).catch((e)=>{
                    console.log(handleError(e));
                })
                
                break;
                case 'BTC':
                    CreateWalletSnapShot(uid,{
                      Wallet:await createBitcoinWallet(),
                      Type:coin
                    }).then((res)=>{
                        saveItem('__FLASHUSDT_U.WALLET__',res.data.data);
                        nav(`/dashboard/coin-view/${coin.toLowerCase()}`,{state:{prevRoute:'/dashboard/coins'}});
                    }).catch((e)=>{
                        console.log(handleError(e));
                    })
              break;
          }

        }
        
    },[]);

 


  return (
    <div style={{paddingTop:36}}>
       {showLoader && <Spinner/>}
     <Head backTo='/dashboard' currentPageTitle='Manage cryptocurrency'/>
      <div className={classes.root}> 

      <div className={classes.txn}>
        {
          Object.entries(coins).map(([,{symbol,name}],i)=><WalletCard key={i} code={symbol} descr={name} convtAmt={USD(userWallet[symbol]?.usd || 0)} rawAmt={userWallet[symbol]?.token} onClick={()=>checkCoinExsistance(symbol)}/>)
        }
      </div>

    </div>
    
    <Nav/>
      
    </div>
  );
});

