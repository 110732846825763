import { decryptWallet, encryptWallet, getItem } from '..';
import networks from '../networks';
import { getTrxToUsdRate, trxToUSD } from '../usdt/usdt';

const TronWeb = require('tronweb');
const bip39 = require('bip39');
const { hdkey } = require('ethereumjs-wallet');

const { tron,aggregators:{chainlink:{trx_usd,eth_usd}}} = networks[process.env.REACT_APP_NETWORK];


export const tronWeb = new TronWeb({
  fullHost: tron.rpcUrl, //https://rpc.ankr.com/http/tron vs https://api.shasta.trongrid.io 
  // headers: { "TRON-PRO-API-KEY": '2361cc74-5762-4aa4-a43f-7b8c2f4f9728' }
}); 



export const formattedPrivateKey=(privateKey='') => privateKey.startsWith('0x') ? privateKey.slice(2) : privateKey;

// Function to generate private key from mnemonic
function getPrivateKeyFromMnemonic() {
  const mnemonic = bip39.generateMnemonic();
  // Generate seed from mnemonic
  const seed = bip39.mnemonicToSeedSync(mnemonic);
  
  // Derive HD wallet
  const hdWallet = hdkey.fromMasterSeed(seed);
  const key = hdWallet.derivePath("m/44'/60'/0'/0/0").getWallet();
  
  return {privateKey:key.getPrivateKeyString(),mnemonic:mnemonic} // Private key in hex format
}


export const createTRC20Wallet = () => {
  // Generate private key from mnemonic
  // const { privateKey, mnemonic } = getPrivateKeyFromMnemonic();

//   // Ensure the private key is in the correct format (64-character hex without '0x')
//   const pk = formattedPrivateKey(privateKey);

//   if (!isValidPrivateKey(pk)) {
//       throw new Error('Invalid private key format.');
//   }


// const address = TronWeb.address.fromPrivateKey(formattedPrivateKey);

// const wallet ={
//   address: address,
//   privateKey: pk,
//   mnemonic: mnemonic
// }

  const wallet = tronWeb.createRandom();
  wallet.privateKey = formattedPrivateKey(wallet.privateKey);
  const encryptedWallet = encryptWallet(wallet);
  return encryptedWallet;
};

function isValidPrivateKey(key) {
  return typeof key === 'string' && /^[0-9a-fA-F]{64}$/.test(key);
}
const o = {
  "mnemonic": {
      "phrase": "afraid wonder lion glimpse health connect beef deer bleak seven hobby open",
      "path": "m/44'/195'/0'/0/0",
      "locale": "en"
  },
  "privateKey": "0x2d71d6404673ba6dbee0ece3ec4e2f4e004bb116472efa5a832227d0af34e532",
  "publicKey": "0x04e4c800701f236faeb6124a864ae0662a474fe8486e846caeeabe65ab07b863fcb4837160f5641cc7d009725ab4e2fd88c58f3a98731097999dfd215dcd482e1a",
  "address": "TNGE1rCNbCTeWQdoc2ZCCFvzU95RumvNBW"
}
// console.log(encryptWallet(o));



export async function checkTRC20Balance(TRC20Wallet) {
  const res = await tronWeb.trx.getBalance(TRC20Wallet);
  // Convert the balance from the smallest unit (usually in "Sun") to the main unit
  const trx = tronWeb.fromSun(res);
  let trxToUsdRate = await getTrxToUsdRate();
  return {
    token:trx,
    usd: trx * trxToUsdRate,
    rate: trxToUsdRate
  }
    
}



export async function checkUSDTBalance(addr,pk) {
  const usdtContract = await tronWeb.contract().at(tron.contract);
  try {
    tronWeb.setPrivateKey(pk);
      // The USDT TRC-20 contract address on the TRON network

      // Call the balanceOf method to get the balance of the wallet
      const balance = await usdtContract.methods.balanceOf(addr).call();

      // Convert the balance from the smallest unit (usually in "Sun") to the main unit
      const adjustedBalance = balance / Math.pow(10, 6);

    return adjustedBalance;
  } catch (error) {
      console.error('Error checking USDT balance:', error);
  }
}

export const tron_fromHex =(hexAddress)=>tronWeb.address.fromHex(hexAddress);

export const tron_isHexAddress = (address)=> /^41[a-fA-F0-9]{40}$/.test(address);

export const isTronAddress = (address)=>TronWeb.isAddress(address);


export const estimateTrc20TransactionCost = async (recipientAddress, amountInUsdt) => {
  try {
    const amountInSun = amountInUsdt * Math.pow(10, 6); // Convert USDT to Sun (1 USDT = 10^6 Sun)
     tronWeb.setAddress('TSQuk7BQeA4VzgK4Qoy4TK2jrXbiCozoiK'); 
    
    // Build the contract call
    const transaction = await tronWeb.transactionBuilder.triggerSmartContract(
      tron.contract, // USDT TRC20 contract address
      "transfer(address,uint256)", // Method to call
      {
        feeLimit: 10000000, // Max TRX to consume
        callValue: 0, // TRC20 transfers don't need TRX sent along
      },
      [
        { type: 'address', value: recipientAddress },
        { type: 'uint256', value: amountInSun }
      ],
      tronWeb.defaultAddress.base58 // Sender's address
    );
    
    if (transaction && transaction.transaction) {
      const energyUsed = transaction.energy_used; // Energy required for the transaction
      const bandwidthUsed = transaction.bandwidth_used; // Bandwidth required for the transaction
      const estimatedFee = energyUsed * tronWeb.toSun(1); // TRX required based on energy (convert to SUN)
      
      return {
        energyUsed,
        bandwidthUsed,
        estimatedFee
      };
    } else {
      throw new Error('Error triggering the smart contract');
    }
  } catch (error) {
    console.error('Error estimating transaction cost:', error);
    throw error;
  }
};





//Send TRC20 
export const sendTrc20 = async (recipientAddress, amountInUsdt,privateKey) => {

  const usdtContract = await tronWeb.contract().at(tron.contract);
  tronWeb.setPrivateKey(privateKey);

      const result = await usdtContract.methods.transfer(recipientAddress,amountInUsdt * Math.pow(10, 6)).send({
          feeLimit: 100000000  // Set a fee limit (100 TRX)
      });
      console.log('methods.transfer: ',result);
      
      return result;
};

export const checkTrc20TransactionStatus = async (transactionId) => {
      const receipt = await tronWeb.trx.getTransactionInfo(transactionId);
      return receipt;
};


//SENDING TRX tokens
export const sendTrx = async (recipientAddress, amountInTrx, privateKey) => {

    // Set private key
    tronWeb.setPrivateKey(privateKey);

    // Build the transaction
    const transaction = await tronWeb.transactionBuilder.sendTrx(
      recipientAddress,
      tronWeb.toSun(amountInTrx),  // Convert TRX to SUN
      tronWeb.defaultAddress.base58
    );

    // Sign the transaction
    const signedTransaction = await tronWeb.trx.sign(transaction);

    // Send the signed transaction
    const trans = await tronWeb.trx.sendRawTransaction(signedTransaction);

    return trans;

    // Fetch transaction info
    const res = await tronWeb.trx.getTransactionInfo(trans.txID);
    console.log('transaction info: ', res);
    
    return res;
};

