import React, { useCallback, useEffect, useState } from 'react';
import Button from '../components/button';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Nav from '../components/nav';
import Usercard from '../components/usercard';
import MainBalanceCard from '../components/balanceCard';
import TxnCard from '../components/txnCard';
import Info from '../components/info';
import { decryptWallet, encryptWallet, getItem, handleError, saveItem } from '../utils';
import Popup from '../components/popup';
import nope from '../images/gradient-hate-emoji-illustration 1.svg';
import tx from "../images/history.svg";
import { useTheme } from '@emotion/react';
import {createEthereumWallet, USD } from '../utils/usdt/usdt';
import {FetchUserWallets, getBTCBalance, getBTCTransactionHistory, getUSDTTransactionHistory, getUSDTTransactionHistory_5 } from '../utils/endpoints';
import {checkUSDTBalance, createTRC20Wallet} from '../utils/tron/tron';
import moment from 'moment';
import Spinner from '../components/spinner';
import { createBitcoinWallet, sendBitcoin, validateBtcAddress } from '../utils/bitcoin';
import PinBox from '../components/pinBox';


export const DEMO_TXN = {
  "success": true,
  "meta": {
    "at": 1672508253000,
    "page_size": 20
  },
  "data": [
    {
      "transaction_id": "de9f4f5fa21d3143de1b516869194e40c24ae3a747ba4903caa0eb4d2e40e751",
      "from": "TXk5gf5Zpb7UvSGeb3m42c4kAe2HVioJ67",
      "to": "TAyZ6icvnQFFn9Cb6h6sXbPjfceRZy85Zd",
      "value": "124000000",
      "type": "Transfer",
      "token_info": {
        "symbol": "USDT",
        "address": "TLa2f6VPqDgRE67v1736s7bJ8Ray5wYjU7",
        "decimals": 6,
        "name": "Tether USD"
      },
      "block_timestamp": 1672508245000
    },
    {
      "transaction_id": "a72b96f9fd1d4e0ab33f3e4e32b6dc8c7bcb15e6d1fb089dd69dd4c2489a6bb8",
      "from": "TAyZ6icvnQFFn9Cb6h6sXbPjfceRZy85Zd",
      "to": "TXk5gf5Zpb7UvSGeb3m42c4kAe2HVioJ67",
      "value": "5000000",
      "type": "Transfer",
      "token_info": {
        "symbol": "USDT",
        "address": "TLa2f6VPqDgRE67v1736s7bJ8Ray5wYjU7",
        "decimals": 6,
        "name": "Tether USD"
      },
      "block_timestamp": 1672508245000
    }
  ]
};



const useStyles = makeStyles((theme) => ({
  root:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'column',
    height: 'calc(100% - 50px)',
    padding: theme.spacing(1), width:'100%',
    '& a':{
      textDecoration: 'none',
      color: theme.palette.primary.main, fontWeight:600,
    }
  },
  ctas:{
    display: 'flex',
    justifyContent: 'space-between', width:'100%',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1.5),
    '& button':{
      backgroundColor: theme.palette.primary.tintMain,
      color: theme.palette.text.primary,
      // padding: theme.spacing(1), 
      fontWeight:400,
      borderRadius:20,
      fontSize:12
    }
  },
  txn: {
    marginTop: theme.spacing(5),
    display: 'flex',justifyContent: 'center', alignItems: 'flex-start',
    flexDirection: 'column', width:'100%', paddingBottom: theme.spacing(10),
    '& h3':{
      color: theme.palette.text.primary, fontWeight:700, fontSize:17, marginBottom:10,
    },
    '& .header':{
      display: 'flex',justifyContent: 'space-between', alignItems: 'center', width: '100%',
      color: theme.palette.text.secondary, fontSize:13, marginBottom:20,
    }
    
  
  },
  chat:{
    position: "fixed", display: "flex", justifyContent: "center",alignItems: "center",
       bottom: '5%',left: 0, borderRadius:'0 50px 50px 0',cursor: 'pointer', padding:10,
       width:90, height:60,  backgroundColor: theme.palette.primary.tintMain, color: theme.palette.text.primary,
       '& path':{
         fill: theme.palette.primary.main
       },
       '& div':{
        display: "flex", justifyContent: "center",alignItems: "center",flexDirection:'column',
        fontSize: '12px', gap:5
       }
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const nav = useNavigate();
  const [user, setUser] = useState(null);
  const [recentTx, setRecentTx] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [userMainWallet, setUserMainWallet] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [balance, setBalance] = useState(0);


  //svg icons
  const more = (<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4999 2C10.4999 3.10457 9.6068 4 8.50506 4H8.49482C7.39308 4 6.49994 3.10457 6.49994 2C6.49994 0.89543 7.39308 0 8.49482 0H8.50506C9.6068 0 10.4999 0.89543 10.4999 2Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.49994 2C4.49994 3.10457 3.6068 4 2.50506 4H2.49482C1.39308 4 0.499939 3.10457 0.499939 2C0.499939 0.89543 1.39308 0 2.49482 0H2.50506C3.6068 0 4.49994 0.89543 4.49994 2Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4999 2C16.4999 3.10457 15.6068 4 14.5051 4H14.4948C13.3931 4 12.4999 3.10457 12.4999 2C12.4999 0.89543 13.3931 0 14.4948 0H14.5051C15.6068 0 16.4999 0.89543 16.4999 2Z" fill="white"/>
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M10.4999 8C10.4999 9.10457 9.6068 10 8.50506 10H8.49482C7.39308 10 6.49994 9.10457 6.49994 8C6.49994 6.89543 7.39308 6 8.49482 6H8.50506C9.6068 6 10.4999 6.89543 10.4999 8Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4999 14C10.4999 15.1046 9.6068 16 8.50506 16H8.49482C7.39308 16 6.49994 15.1046 6.49994 14C6.49994 12.8954 7.39308 12 8.49482 12H8.50506C9.6068 12 10.4999 12.8954 10.4999 14Z" fill="white"/>
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M4.49994 8C4.49994 9.10457 3.6068 10 2.50506 10H2.49482C1.39308 10 0.499939 9.10457 0.499939 8C0.499939 6.89543 1.39308 6 2.49482 6H2.50506C3.6068 6 4.49994 6.89543 4.49994 8Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.49994 14C4.49994 15.1046 3.6068 16 2.50506 16H2.49482C1.39308 16 0.499939 15.1046 0.499939 14C0.499939 12.8954 1.39308 12 2.49482 12H2.50506C3.6068 12 4.49994 12.8954 4.49994 14Z" fill="white"/>
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M16.2499 8C16.2499 8.9665 15.4664 9.75 14.4999 9.75H14.491C13.5245 9.75 12.741 8.9665 12.741 8C12.741 7.0335 13.5245 6.25 14.491 6.25H14.4999C15.4664 6.25 16.2499 7.0335 16.2499 8Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4999 14C16.4999 15.1046 15.6068 16 14.5051 16H14.4948C13.3931 16 12.4999 15.1046 12.4999 14C12.4999 12.8954 13.3931 12 14.4948 12H14.5051C15.6068 12 16.4999 12.8954 16.4999 14Z" fill="white"/>
    </svg>
    );
    const arrowUp = (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M12.7071 1.29289C13.0976 1.68342 13.0976 2.31658 12.7071 2.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289Z" fill="white"/>
      <path d="M12.2444 0.289816C12.525 0.371731 12.9065 0.515339 13.1956 0.804442C13.4847 1.09355 13.6283 1.47497 13.7102 1.75566C13.8008 2.06599 13.8605 2.41701 13.9018 2.77008C13.9845 3.4788 14.0051 4.31068 13.999 5.08421C13.9929 5.86374 13.9594 6.61224 13.9276 7.16387C13.9117 7.44031 13.8962 7.66877 13.8845 7.82876C13.8788 7.90804 13.8685 8.03764 13.865 8.08221L13.8649 8.08344C13.8185 8.63377 13.3347 9.04287 12.7844 8.99647C12.2341 8.95007 11.8256 8.46638 11.872 7.91609C11.8751 7.87652 11.8843 7.75946 11.8898 7.68378C11.9008 7.53234 11.9157 7.314 11.9309 7.04894C11.9615 6.51757 11.9933 5.80424 11.9991 5.06853C12.0049 4.32682 11.9839 3.59001 11.9153 3.002C11.8808 2.7067 11.8116 2.39695 11.7645 2.23556C11.6031 2.18846 11.2933 2.11924 10.998 2.08476C10.41 2.01611 9.67318 1.99512 8.93146 2.00092C8.19575 2.00668 7.48242 2.03846 6.95105 2.06904C6.68599 2.0843 6.46765 2.09917 6.31621 2.11017C6.24052 2.11567 6.12394 2.12485 6.08438 2.12797C5.53409 2.17431 5.04992 1.76584 5.00353 1.21554C4.95714 0.665204 5.36566 0.181463 5.916 0.135071L5.91828 0.134891C5.96383 0.1313 6.09259 0.121148 6.17125 0.115431C6.33124 0.103805 6.5597 0.0882541 6.83614 0.0723449C7.38778 0.0405985 8.13627 0.0070863 8.91581 0.00098511C9.68934 -0.00506911 10.5212 0.0155118 11.2299 0.0982586C11.583 0.139481 11.934 0.199252 12.2444 0.289816Z" fill="white"/>
      </svg>
      );
  const arrowDown = (<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M1.79289 12.7071C1.40237 12.3166 1.40237 11.6834 1.79289 11.2929L12.7929 0.292893C13.1834 -0.0976315 13.8166 -0.0976315 14.2071 0.292893C14.5976 0.683417 14.5976 1.31658 14.2071 1.70711L3.20711 12.7071C2.81658 13.0976 2.18342 13.0976 1.79289 12.7071Z" fill="white"/>
    <path d="M2.25565 13.7102C1.97495 13.6283 1.59354 13.4847 1.30443 13.1956C1.01532 12.9064 0.87171 12.525 0.789795 12.2443C0.699231 11.934 0.639461 11.583 0.598241 11.2299C0.515499 10.5212 0.494926 9.68932 0.500989 8.91579C0.507098 8.13626 0.540619 7.38776 0.572372 6.83613C0.588284 6.55969 0.603839 6.33123 0.615467 6.17124C0.62123 6.09196 0.631451 5.96236 0.634966 5.91779L0.635062 5.91656C0.681461 5.36623 1.16525 4.95713 1.71559 5.00353C2.26589 5.04993 2.67439 5.53362 2.62805 6.08391C2.62493 6.12348 2.61571 6.24054 2.61021 6.31622C2.5992 6.46766 2.58432 6.686 2.56907 6.95106C2.53848 7.48243 2.50669 8.19576 2.50093 8.93147C2.49511 9.67318 2.5161 10.41 2.58475 10.998C2.61922 11.2933 2.68844 11.603 2.73554 11.7644C2.89693 11.8115 3.20669 11.8808 3.502 11.9152C4.09002 11.9839 4.82682 12.0049 5.56854 11.9991C6.30425 11.9933 7.01758 11.9615 7.54895 11.931C7.81401 11.9157 8.03235 11.9008 8.18379 11.8898C8.25948 11.8843 8.37606 11.8751 8.41562 11.872C8.96591 11.8257 9.45008 12.2342 9.49647 12.7845C9.54286 13.3348 9.13434 13.8185 8.584 13.8649L8.58172 13.8651C8.53617 13.8687 8.40741 13.8789 8.32875 13.8846C8.16876 13.8962 7.9403 13.9117 7.66386 13.9277C7.11222 13.9594 6.36373 13.9929 5.58419 13.999C4.81066 14.0051 3.97878 13.9845 3.27006 13.9017C2.917 13.8605 2.56597 13.8007 2.25565 13.7102Z" fill="white"/>
    </svg>
    );
    const chat = (<svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M12.3945 1.31147C11.1486 1.22943 9.84919 1.22959 8.60585 1.31147C4.672 1.57054 1.54331 4.71804 1.28595 8.66337C1.23822 9.395 1.23822 10.1516 1.28595 10.8832C1.38206 12.3566 2.029 13.6794 2.74063 14.7449C2.91787 15.0918 2.83591 15.6455 2.44338 16.3824L2.42308 16.4205C2.29183 16.6667 2.15761 16.9185 2.07931 17.1356C1.99566 17.3676 1.89862 17.7641 2.13239 18.159C2.34511 18.5183 2.68333 18.6457 2.94185 18.6953C3.15334 18.7358 3.40997 18.7419 3.63956 18.7473L3.68158 18.7483C4.91284 18.778 5.78643 18.4249 6.47896 17.919C6.58524 17.8413 6.66363 17.7842 6.72465 17.7413C6.81714 17.7765 6.93927 17.8262 7.10921 17.8955C7.58107 18.088 8.1152 18.2028 8.60585 18.2351C9.84919 18.317 11.1486 18.3172 12.3945 18.2351C16.3283 17.9761 19.457 14.8286 19.7144 10.8832C19.7621 10.1516 19.7621 9.395 19.7144 8.66337C19.457 4.71804 16.3283 1.57054 12.3945 1.31147Z" fill="#141B34"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.50015 12.75C7.08594 12.75 6.75015 12.4142 6.75015 12C6.75015 11.5858 7.08594 11.25 7.50015 11.25H13.5002C13.9144 11.25 14.2502 11.5858 14.2502 12C14.2502 12.4142 13.9144 12.75 13.5002 12.75H7.50015ZM7.50015 8.75C7.08594 8.75 6.75015 8.41421 6.75015 8C6.75015 7.58579 7.08594 7.25 7.50015 7.25H10.5002C10.9144 7.25 11.2502 7.58579 11.2502 8C11.2502 8.41421 10.9144 8.75 10.5002 8.75H7.50015Z" fill="#141B34"/>
      <path d="M21.2109 10.9818C20.9049 15.6726 17.1833 19.4239 12.4927 19.7328C11.1811 19.8192 9.8159 19.819 8.50699 19.7328C7.9676 19.6973 7.6979 19.6795 7.60469 19.7604C7.53018 19.8251 7.49343 19.9169 7.50268 20.0151C7.51426 20.138 7.69215 20.2867 8.04792 20.584C9.19436 21.5422 10.6202 22.1635 12.2143 22.2681C13.3887 22.3452 14.6134 22.345 15.7854 22.2681C16.2513 22.2376 16.7584 22.1291 17.2071 21.9467L17.3042 21.9073C17.3931 21.8712 17.4376 21.8532 17.4861 21.8501C17.5221 21.8478 17.5618 21.8527 17.5961 21.8636C17.6424 21.8783 17.6815 21.9068 17.7596 21.9636C18.4188 22.4433 19.2501 22.7773 20.4183 22.7492L20.4589 22.7483C20.6738 22.7433 20.9188 22.7375 21.1213 22.6988C21.3703 22.6513 21.703 22.5278 21.9127 22.1749C22.1441 21.7857 22.0469 21.3956 21.9665 21.1738C21.8916 20.9668 21.7637 20.7279 21.6409 20.4984L21.6211 20.4613C21.2533 19.7735 21.1873 19.2761 21.3405 18.974C22.0118 17.9722 22.6249 16.7246 22.7161 15.3322C22.7611 14.6441 22.7611 13.9326 22.7161 13.2446C22.6581 12.3598 22.4466 11.5177 22.1079 10.7447C21.8765 10.2165 21.7608 9.95246 21.5772 9.94183C21.5331 9.93928 21.482 9.94827 21.4414 9.96568C21.2724 10.0383 21.2519 10.3528 21.2109 10.9818Z" fill="#141B34"/>
      </svg>
      );

    const theme = useTheme();
    useEffect(() => {
      //ftech user detasils from storage
     LoadResources();
     const USER = getItem('__FLASHUSDT_USER__');
     if(!USER){
        nav('/signin');
     }else{
       setUser(getItem('__FLASHUSDT_USER__'));
      }

    },[]);

    const LoadResources = async ()=>{
      const {Main} = getItem('__FLASHUSDT_U.WALLET__') || {};
      if(Main){
        const wallet = decryptWallet(Main.Wallet);
        setUserMainWallet(wallet);
        
        const trns = getUSDTTransactionHistory(wallet.address,5);
        const bal = checkUSDTBalance(wallet.address, wallet.privateKey);
     
        Promise.all([bal,trns]).then(([bln,txn])=>{
          setBalance(bln);
          setRecentTx(txn.data.data);
          setShowLoader(false);

        }).catch((err)=> {
          console.log(err);
          
        })
  
      }else{
        fetchWallets();
      }
      
      
    };

    const fetchWallets = () => {
      FetchUserWallets(user.UID).then((res) => {
        saveItem('__FLASHUSDT_U.WALLET__',res.data.data);
      });
    };

    const confirmElegibility = useCallback((path)=> {
      if(user.AccountType !== 'FREE'){
        //proceed with the transaction
        nav(path);
      }else{
        setShowPopup(true);
      }
    });


  return (
    <div>
    {showLoader && <Spinner/>}
    <div className={classes.root}> 
      <Usercard/>
      <MainBalanceCard WalletBalance={balance}/>

      <div className={classes.ctas}>
       <Button icon={arrowUp} loading={false} text='Send' onClick={()=>confirmElegibility('/dashboard/send/usdt')}/>
       <Button icon={arrowDown} loading={false} text='Receive' onClick={()=>confirmElegibility('/dashboard/receive/usdt')}/>
       {/* <Button icon={more} loading={false} text='More' onClick={test}/> */}
      </div>

      {user?.AccountType ==='FREE' && <Info display={true} type='error' style={{fontSize:12, border:'1px solid red'}}>
          Your account is inactive. Activate account with Demo or Premium licence key. <a onClick={()=>nav('/dashboard/activation',{state:{prevRoute:'/dashboard'}})}>Click here</a> 
      </Info>}


      <div className={classes.txn}>
        <h3>Recent Transactions</h3>
        <div className="header">
          <div>Transaction type</div>
          <div>Amount</div>
        </div>

        {
          recentTx.length > 0? recentTx.map((t,index) => <TxnCard
          key={index}
           amt={USD(t?.value/ Math.pow(10, 6))}
           out={t?.from.toLowerCase() === userMainWallet?.address.toLowerCase()}
           date = {moment(+t?.timeStamp || +t?.block_timestamp).format("MMM D, YYYY")}
           className={(t?.from.toLowerCase() === userMainWallet?.address.toLowerCase())?'show':'hide'}
           onClick={()=>nav(`/dashboard/txn-detail?coin=usdt`,{state:{tx:t, txId:t.transaction_id,prevRoute:`/dashboard`}})}
           /> ) 
          :
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:'100%',gap:20}}>
            <img src={tx} alt="" style={{opacity:0.05,filter:`invert(${theme.palette.mode ==='dark'?'0':'1'})`}} width={100}/>
            <p style={{color:theme.palette.text.secondary}}>You have no transactions</p>
        </div>
        }


        {recentTx.length >= 4 && <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
          <Button text='View all transactions' style={{marginTop:14}} onClick={()=>nav('/dashboard/txn/usdt',{state:{prevRoute:'/dashboard'}})}/> 
          </div>  }
      </div>

    </div>
    
    <Nav/>

    <div className={classes.chat} role='button' tabIndex={-1} onClick={()=>nav('/dashboard/support',{state:{prevRoute:'/dashboard'}})}>
      <div>
      {chat}
        <small>Contact us</small>
      </div>
    </div>

    <Popup display={showPopup} setDisplay={setShowPopup}>
      <img src={nope} alt="No tx illustration" />
      <div style={{fontSize:12,fontWeight:400,textAlign:'center', margin:'15px 0',color:theme.palette.text.secondary}}>Your account is inactive. You can not perform transaction yet. Click get license key to activate account and perform transactions. </div>

      <Button text='Get License key' onClick={()=>nav('/dashboard/activation',{state:{prevRoute:'/dashboard'}})}/>
  
    </Popup>

      
    </div>
  );
};

export default Dashboard;
